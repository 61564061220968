/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Hromosvod"}>
        <Column className="pb--30 pt--30" name={"h8kcino3qej"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Instalace hromosvodu na RD<br>"}>
              </Title>

              <Text className="text-box fs--20 w--500 ls--0 lh--14" content={"Na tomto rodinném domě proběhla instalace hromosvodu. Přání zákazníka bylo, aby hromosvod co nejméně narušoval vzhled domu, což se myslíme povedlo. \n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--0" name={"9tv77693cw"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3 pt--20" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/da8af83335b04d45b442713f31a1fee5_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/da8af83335b04d45b442713f31a1fee5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/da8af83335b04d45b442713f31a1fee5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/da8af83335b04d45b442713f31a1fee5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/da8af83335b04d45b442713f31a1fee5_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11759/da8af83335b04d45b442713f31a1fee5_s=2000x_.jpg 2000w"} position={{"x":"-31.74603174603177%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/c0c54fa5d5b34ec9b5258628e7e37f06_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/c0c54fa5d5b34ec9b5258628e7e37f06_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/c0c54fa5d5b34ec9b5258628e7e37f06_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/c0c54fa5d5b34ec9b5258628e7e37f06_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/c0c54fa5d5b34ec9b5258628e7e37f06_s=1400x_.jpg 1400w"} position={{"x":"-5.026455026455025%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/65e79a8a5a4b4334931565754093e5c2_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/65e79a8a5a4b4334931565754093e5c2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/65e79a8a5a4b4334931565754093e5c2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/65e79a8a5a4b4334931565754093e5c2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/65e79a8a5a4b4334931565754093e5c2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11759/65e79a8a5a4b4334931565754093e5c2_s=2000x_.jpg 2000w"} position={{"x":"-81.73544973544973%","y":"-0.26455026455026454%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/08f4f178da96467098b18ac5f221fbcb_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/08f4f178da96467098b18ac5f221fbcb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/08f4f178da96467098b18ac5f221fbcb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/08f4f178da96467098b18ac5f221fbcb_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/08f4f178da96467098b18ac5f221fbcb_s=1400x_.jpg 1400w"} position={{"x":"-5.5555555555555545%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/be5c4ff7934e43248face21cc24bb2a2_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/be5c4ff7934e43248face21cc24bb2a2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/be5c4ff7934e43248face21cc24bb2a2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/be5c4ff7934e43248face21cc24bb2a2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/be5c4ff7934e43248face21cc24bb2a2_s=1400x_.jpg 1400w"} position={{"x":"0%","y":"-65.02116402116401%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}